<script setup>
import { Head, router } from "@inertiajs/vue3";
import Banner from "@/Components/Banner.vue";
import { computed, ref } from "vue";
import { useDisplay } from "vuetify";
import LanguageSwitcher from "@/Components/LanguageSwitcher.vue";
import AppProcessor from "./AppProcessor.vue";

defineProps({
  title: String,
});

const drawerOpen = ref(false);
const temporaryDrawer = computed(() => {
  return useDisplay().smAndDown.value;
});

const headerImages = [
  "/img/header-images/header-image1.jpg",
  "/img/header-images/header-image2.jpg",
];

const randomImage = () => {
  var img = sessionStorage.getItem("headerImage");
  const timestamp = sessionStorage.getItem("headerImageTimestamp");
  console.log(
      timestamp,
      Math.floor(Date.now() / 1000) -
      timestamp +
      " " +
      Math.floor(Date.now() / 1000)
  );
  if (img == null || Math.floor(Date.now() / 1000) - timestamp > 5) {
    console.log("New Image");
    img = headerImages[Math.floor(Math.random() * headerImages.length)];
    sessionStorage.setItem(
        "headerImageTimestamp",
        Math.floor(Date.now() / 1000)
    );
    sessionStorage.setItem("headerImage", img);
  }
  return img;
};
</script>

<template>
  <div>
    <app-processor></app-processor>

    <Head :title="title" />

    <v-card class="tw-min-h-screen tw-bg-gray-100">
      <v-layout>
        <v-app-bar color="blue-darken-4" density="comfortable">
          <v-img
              src="/img/fll_horz_i.png"
              class="tw-ml-3 tw-mr-auto flex-shrink-1"
              max-height="100"
              max-width="150"
              contain
          ></v-img>

          <LanguageSwitcher />
        </v-app-bar>

        <v-main>
          <!-- Page Content -->
          <main>
            <header class="tw-bg-white tw-shadow">
              <v-img
                  cover
                  style="
                                    max-height: 100px;
                                "
                  :src="randomImage()"
              >
              </v-img>
            </header>
            <slot />
          </main>
        </v-main>
      </v-layout>
    </v-card>
  </div>
</template>
